import { useRef } from "react";
import { Overlay, Tooltip } from "react-bootstrap";

export const ControlRadio = ({
  id,
  label,
  option1,
  option2,
  onChange,
  data,
  start,
  end,
  errors = [],
  ...options
}) => {
  const target = useRef();
  const error = errors.find((e) => e.path === id);

  return (
    <>
      <div className="row w-100 d-flex">
        <div className="col-sm-4">
          <label
            htmlFor="formFile"
            className="col-sm-4 col-form-label fst-italic"
          >
            {label}
          </label>
        </div>
        <div className="col-sm-8 d-flex row">
          <div className="form-check col-sm-6">
            <input
              className="form-check-input"
              type="radio"
              name={id}
              id={"flexRadio" + option1}
              value={option1}
              onChange={onChange}
              // defaultChecked={data?.[id] === option1 || !data?.[id]} 
            />
            <label className="form-check-label" htmlFor={"flexRadio" + option1}>
              {option1}
            </label>
          </div>
          <div className="form-check col-sm-6">
            <input
              className="form-check-input"
              type="radio"
              name={id}
              id={"flexRadio" + option2}
              value={option2}
              onChange={onChange}
              // defaultChecked={data?.[id] === option2}
            />
            <label className="form-check-label" htmlFor={"flexRadio" + option2}>
              {option2}
            </label>
          </div>
        </div>
      </div>
      <Overlay target={target.current} show={error} placement="right">
        {(props) => (
          <Tooltip
            {...{ ...props, style: { ...(props.style || {}), opacity: 0.8 } }}
          >
            {error?.message}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};
