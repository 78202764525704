import { useState, useEffect} from "react";
import { useAbm } from "../../services/abm";
// import { PrivateLayoutContext } from "../../components/layout/PrivateLayout";
import { Table } from "../../components/abm/Table";
import { TiDelete, TiEdit, TiPlus } from "react-icons/ti";
import { MdSave } from "react-icons/md";
import { RiErrorWarningFill } from "react-icons/ri";
// import { Sections } from "../../components/layout/Sections";
import { StoreForm } from "../../components/abm/stores/StoreForm";
import { PopupDelete } from "../../components/abm/PopupDelete";
// import { useAppContext } from "../../App";

import * as yup from 'yup';
import { Abm } from "./_Abm";
import { ProcessUnauthorized } from "../../config/utils";

export const schemaAdd = yup.object().shape({
  adminEmail: yup.string().email("Email no es válido").required("Email es un campo obligatorio"),
  password: yup.string().required("Contraseña es un campo obligatorio"),
  namespace: yup.string().required("Subdominio es un campo obligatorio"),
  name: yup.string().required("Nombre es un campo obligatorio"),
  sector: yup.string().required("Sector es un campo obligatorio"),
  address: yup.object().shape({
    street: yup.string(),
    externalNumber: yup.string(),
    zipCode: yup.string(),
    province: yup.string(),
    city: yup.string()
  }),
  socialData: yup.object().shape({
    facebook: yup.string(),
    instagram: yup.string(),
    mobilePhoneNumber: yup.string()
  })
});

export const schemaEdit = yup.object().shape({
  namespace: yup.string().required("Subdominio es un campo obligatorio"),
  name: yup.string().required("Nombre es un campo obligatorio"),
  sector: yup.string().required("Sector es un campo obligatorio"),
  address: yup.object().shape({
    street: yup.string(),
    externalNumber: yup.string(),
    zipCode: yup.string(),
    province: yup.string(),
    city: yup.string()
  }),
  socialData: yup.object().shape({
    facebook: yup.string(),
    instagram: yup.string(),
    mobilePhoneNumber: yup.string()
  })
});

const Stores = () => {
  const { getAll, create, update, remove, getOne, validateUser } = useAbm({ entity: "stores" });
  const [rows, setRows] = useState([]);
  const [editItem, setEditItem] = useState(null);
  const [removeItem, setRemoveItem] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [error, setError] = useState(null);
  const [activeKey, setActiveKey] = useState("administrar");

  const administrarBreadcrumb = [
    { caption: "Tiendas", href: "#" },
    { caption: "Administrar", href: "#" }
  ];

  const aniadirBreadcrumb = [
    { caption: "Tiendas", href: "#" },
    { caption: "Añadir", href: "#" }
  ];
  const [breadcrumb, setBreadcrumb] = useState(administrarBreadcrumb);

  const columns = [
    { field: "name", caption: "NOMBRE" },
    { field: "namespace", caption: "SUBDOMINIO" },
    { field: "address", caption: "UBICACION", render: (row) => row.address.street + ' ' + row.address.externalNumber },
    { field: "sector", caption: "MODELO DE NEGOCIO", render: (row) => row.name },
    { field: "redComercio", caption: "RED DE COMERCIO", render: (row) => <a href="#">Ver todo</a> },
  ];

  const actions = ({ row }) => [
    { name: "editar", caption: "Editar", icon: <TiEdit size={22} color="orange" />, onClick: (row) => { handleEditItem(row); } },
    { name: "eliminar", caption: "Eliminar", icon: <TiDelete size={22} color="red" />, onClick: (row) => { handleRemoveItem(row); } },
  ];

  const handleRemoveItem = (row) => {
    setRemoveItem(row);
    setShowConfirmDelete(true);
  }

  const handleRemoveItemConfirm = async ({ user, password }) => {
    setShowConfirmDelete(false);
    const response = await validateUser(user, password, removeItem.namespace);
    if (response) {
      remove(removeItem.namespace)
    }
    setRemoveItem(null);
  }

  const fetchData = () => {
    getAll()
      .then(data => {
        setRows(data.results.map(item => ({ ...item, id: item.namespace })));
      })
      .catch(reason => {
        handleCatch(reason);
      })
  }

  const handleEditItem = (row) => {
    setEditItem(schemaEdit.cast({ ...row, sector: row.sector.name }));
  }

  const handleFormCancel = () => {
    setEditItem(null);
    handleSelectTab("administrar");
  }
  const handleAddConfirm = (data) => {
    const redes = data.relatedStores.split(',');
    data.relatedStores = redes;
    create(data).then(result => {
      handleSelectTab("administrar");
    }).catch(error => {
      handleCatch(error);
      setError(error?.headers?.get("message") || "Error agregando tienda");
    });
  }

  const handleEditConfirm = (data) => {
    const redes = data.relatedStores.split(',');
    data.relatedStores = redes[0] === "" ? [] : redes;
    update(data, data.namespace).then(result => {
      handleSelectTab("administrar");
    }).catch(error => {
      handleCatch(error);
      setError(error?.headers?.get("message") || "Error guardando cambios");
    });
  }

  const tabs = [
    {
      key: "administrar",
      title: "Administrar",
      content: () => !editItem
        ? <Table columns={columns} rows={rows} actions={actions} />
        : <StoreForm item={editItem} onCancel={handleFormCancel} schema={schemaEdit} onConfirm={handleEditConfirm} confirmLabel={<><MdSave size={18} color="white" /> Guardar</>} cancelLabel={<><TiDelete size={22} color="white" /> Cancelar</>} title={`Editando la tienda: ${editItem.name}`} errorState={[error, setError]} />,
      onSelect: () => {
        //mock api result
        setEditItem(null);
        fetchData();
      }
    },
    {
      key: "añadir",
      title: "Añadir",
      content: () => <StoreForm item={schemaAdd.getDefault()} schema={schemaAdd} onCancel={handleFormCancel} onConfirm={handleAddConfirm} confirmLabel={<><TiPlus size={22} color="white" />Añadir</>} errorState={[error, setError]} cancelLabel={<><TiDelete size={22} color="white" /> Cancelar</>} />,
      onSelect: () => {
      }
    },
  ]

  const handleCloseConfirmDelete = () => {
    console.log("cerrando dialogo confirmar ")
    setRemoveItem(null);
    setShowConfirmDelete(false);
  }

  const handleCatch = (error) => {
    console.log(error);
    if (error?.status === 401) {
      ProcessUnauthorized()
    }
  }

  const handleSelectTab = (key) => {
    tabs.find(tab => tab.key === key)?.onSelect();
    setActiveKey(key);
  }

  useEffect(() => {
    if (activeKey === "añadir") setBreadcrumb(aniadirBreadcrumb);
    else setBreadcrumb(administrarBreadcrumb);
  }, [activeKey]);

  return (
    <Abm
      PopupDelete={
        <PopupDelete
          message={<>Estás a punto de eliminar la tienda <b>{removeItem?.name}</b><br />Esta accion no se puede deshacer.</>}
          onClose={handleCloseConfirmDelete}
          show={showConfirmDelete}
          showLogin={true}
          onConfirm={handleRemoveItemConfirm}
          onCancel={handleCloseConfirmDelete}
          title={<><RiErrorWarningFill color="var(--bs-danger)" size={26} /><span className="px-3">¡Atención!</span></>}
        />
      }
      keyState={[activeKey, setActiveKey]}
      tabs={tabs}
      breadcrumb={breadcrumb}
      title="Tiendas"
    />
  );
}

export default Stores;