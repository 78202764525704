import React, { useEffect, useState } from 'react';
import { Container, Nav, Tab } from 'react-bootstrap';

export const Sections = ({ tabs = [], activeKey, onSelectTab }) => {
  const [navClass, setNavClass] = useState("");
  const [sectorId, setSectorId] = useState(0);
  
  useEffect(() => {
    const sectorId = localStorage.getItem("sectorId");
    setSectorId(sectorId);
    switch (`${sectorId}`) {
      case "1":
        setNavClass("primary");
        break;
      case "2":
        setNavClass("secondary");
        break;
      case "3":
        setNavClass("tertiary");
        break;
      case "4":
        setNavClass("quaternary");
        break;
      case "5":
        setNavClass("quinary");
        break;
      default:
        setNavClass("primary");
        break;
    }
  }, []);

  return (
    <Tab.Container
      activeKey={activeKey}
      onSelect={(k) => onSelectTab(k)}
      style={{ flex: 1 }}
      unmountOnExit={true}
    >
      <header className="header-index">
        <div className="px-4 d-flex flex-row">
          <Nav style={{ flex: 1 }}>
            {tabs.map((tab) => (
              <Nav.Item
                key={tab.key}
                className={tab.key === activeKey ? `${navClass} active` : `${navClass}`}
              >
                <Nav.Link eventKey={tab.key} href="#">
                  {tab.title}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <div className="px-4 search">
            <input type="text" placeholder="Búsqueda" /*  value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}*//>
          </div>
        </div>
      </header>
      <Tab.Content className={sectorId === 5 ? "quinary" : ""}>
        {tabs.map((tab) => (
          <Tab.Pane key={tab.key} eventKey={tab.key}>
            <Container className="mw-100 d-flex flex-column flex-grow-1">
              {tab.content()}
            </Container>
          </Tab.Pane>
        ))}
      </Tab.Content>
    </Tab.Container>
  );
};
