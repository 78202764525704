
import SvgNext from "../assets/Next";
import "./css/menuDesplegable2.css"
import { useNavigate } from 'react-router-dom';

export default function MenuDesplegable({ categorie, parentCategoryUnFiltered }) {
    const navigate = useNavigate();
    const handleSelectCategory = (category) => {
        navigate(`/products?category=${category}`);
      };
    const parentCategoryName = parentCategoryUnFiltered?.name

    const childCategories = categorie?.categories?.filter(
        (category) => category.parentCategory !== null
            && category?.parentCategory[0]?.parentCategory === null
            && category?.parentCategory[0]?.name === parentCategoryName
    );

    // categorie?.categories?.map((category) => {
    //     // console.log(category?.parentCategory );
    //     if (category.parentCategory !== null){
    //         if(category?.parentCategory[0]?.parentCategory !== null){
    //             if(category?.parentCategory[0]?.parentCategory[0]?.parentCategory){
    //                 console.log(category?.parentCategory[0]); 
    //             }
    //         }
    //     }
    //     return "espacio"
    // })
    const Categories3 = categorie?.categories?.filter(
        (category) => category.parentCategory !== null
            && category?.parentCategory[0]?.parentCategory !== null
            && category?.parentCategory[0]?.parentCategory[0]?.name === parentCategoryName
    );
    const Categories4 = categorie?.categories?.filter(
        (category) => category.parentCategory !== null
            && category?.parentCategory[0]?.parentCategory !== null
            && category?.parentCategory[0]?.parentCategory[0].parentCategory !== null
            && category?.parentCategory[0]?.parentCategory[0]?.parentCategory[0].name === parentCategoryName
    );
    
    return (
        <div className="menu-container">
            <div className="triangulo-equilatero"></div>
            <ul className="menu-vertical">
                <h2 className="menu-vertical-title">#IDRAETDERMOPURITY</h2>

                {childCategories?.map((child2, index) => {
                    return (
                        <li className="seccion" key={index}>
                            <a className="category-section" onClick={() => handleSelectCategory(child2.name)}> {child2.name} <SvgNext width={14} fill={"var(--white-color)"}></SvgNext></a>
                            <ul className="menu-final">
                                <ul>
                                    <h2 className="parent-category" onClick={() => handleSelectCategory(child2.name)}> {child2.name} </h2>
                                    <div className="grid-container">
                                        {Categories3?.map((child3, childIndex) => {
                                            return (
                                                <div key={childIndex}>
                                                        {child3.parentCategory[0].id === child2.id && (
                                                            <h3 className="seccion" onClick={() => handleSelectCategory(child3.name)}> {child3.name} </h3>
                                                        )}
                                                    <ul>
                                                    {Categories4?.map((child4, child4Index) => {
                                                        return (
                                                            <div> 
                                                            {child4.parentCategory[0].id === child3.id && 
                                                             child4.parentCategory[0].parentCategory[0].id === child2.id && (
                                                                <li className="seccion" onClick={() => handleSelectCategory(child4.name)}> {child4.name} </li>
                                                             )} 
                                                            </div>
                                                        );
                                                    })}
                                                    </ul>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </ul>
                            </ul>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
