/* eslint-disable react/prop-types */
import { useState } from "react";
import SvgBack from "../assets/Back";
import SvgNext from "../assets/Next";
import estilos from "./css/slider5.css";
import { ShoppingCartAdd } from "../assets/icons";
import { Link } from "react-router-dom";
import { useCart } from "./hooks/useCart";

export const ImgContainer5 = ({ imagen, description, precio, product }) => {
    console.log(product);
    const { addToCart } = useCart();
    const imagenFiltered = imagen.filter((img) => img.description === "principal")
    return (
        <div className="img-container-5">
            <Link to={`/producto/${product.id}`}><img src={`data:image/png;base64,${imagenFiltered[0]?.base64}`} alt="imagen" /> </Link>
            <div className="producto-info">
                <div>
                    <h3>$ {precio} ARS</h3>
                    <button onClick={() => addToCart(product)}><ShoppingCartAdd width={20}></ShoppingCartAdd></button>
                </div>
                {/* <h3> {product.title} </h3> */}
                <p> {product.title} </p>
                {/* <p>{description}</p> */}
            </div>
        </div>
    )
}

export default function Slider5({ product }) {
    const [imagenActual, setImagenActual] = useState(4);
    const cantidad = product?.length;
    // Return prematuro para evitar errores
    // if (!Array.isArray(product) || cantidad === 0) return;

    const siguienteImagen = () => {
        setImagenActual(imagenActual === cantidad - 1 ? 4 : imagenActual + 1);
    };

    const anteriorImagen = () => {
        setImagenActual(imagenActual === 4 ? cantidad - 1 : imagenActual - 1);
    };

    return (
        <div className="slider-container">
            <button onClick={anteriorImagen}><SvgBack height={15} width={15} fill={"red"}></SvgBack></button>
            {product.map((product, index) => {

                return (
                    <div key={product.id}
                        className={
                            imagenActual === index
                                ? `${estilos.slide} ${estilos.active}`
                                : estilos.slide
                        }>
                        {imagenActual === index && (
                            <ImgContainer5 imagen={product.image} description={product.categorie} precio={product.price} product={product}></ImgContainer5>
                        )}
                        {imagenActual === index + 1 && (
                            <ImgContainer5 imagen={product.image} description={product.categorie} precio={product.price} product={product}></ImgContainer5>
                        )}
                        {imagenActual === index + 2 && (
                            <ImgContainer5 imagen={product.image} description={product.categorie} precio={product.price} product={product}></ImgContainer5>
                        )}
                        {imagenActual === index + 3 && (
                            <ImgContainer5 imagen={product.image} description={product.categorie} precio={product.price} product={product}></ImgContainer5>
                        )}
                        {imagenActual === index + 4 && (
                            <ImgContainer5 imagen={product.image} description={product.categorie} precio={product.price} product={product}></ImgContainer5>
                        )}
                    </div>
                );
            })}
            <button onClick={siguienteImagen}><SvgNext height={15} width={15} fill={"red"}></SvgNext></button>
        </div>
    )
}

