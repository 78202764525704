import { useEffect, useRef, useState } from "react";
import MenuDesplegable from "./MenuDesplegable";
import { getAll } from "../../../services/abm";
import "./css/nav2.css";
import { NavLink, useNavigate } from "react-router-dom";
import { HOME_LANDING, PROMOS } from "../../../config/router/routes";

export default function Nav2() {
  const [categorie, setCategories] = useState({ categories: [] });
  const componentMounted = useRef(true);
  const navigate = useNavigate();
  const handleSelectCategory = (category) => {
          navigate(`/products?category=${category}`);
        };
  useEffect(() => {
    getAll("categories")
      .then((res) => {
        if (componentMounted.current) {
          const resCategorie = res;
          setCategories(resCategorie);
          // console.log(resCategorie);
        }
      })
      .catch((err) => console.log(err));

    return () => {
      // This code runs when component is unmounted
      componentMounted.current = false; // (4) set it to false when we leave the page
    };
  }, []);

  const parentCategoryUnFiltered = categorie.categories?.filter(
    (category) => category.parentCategory === null
  );
  // console.log(parentCategoryUnFiltered);
  console.log(parentCategoryUnFiltered);

  return (
    <nav className="nav-container">
      <ul className="menu-horizontal">
        <NavLink to={ `/${HOME_LANDING}`}  className="header-link"> HOME </NavLink>
        <NavLink to={ `/${PROMOS}`}  className="header-link"> PROMOS </NavLink>
        {parentCategoryUnFiltered.map((parentCat) =>
            parentCat.isPublic === true && (
              <li className="contenedor-relative" key={parentCat.id}>
                <a href="#" onClick={() => handleSelectCategory(parentCat.name)}>{parentCat.name.replace(/publico|privado/gi, "").trim()}</a>
                <MenuDesplegable
                  categorie={categorie}
                  parentCategoryUnFiltered={parentCat}
                ></MenuDesplegable>
              </li>
            )
        )}
      </ul>
    </nav>
  );
}
