import Registrate from "./Registrate";
import Slider from "./Slider"
import Slider2 from "./Slider2";
import Slider3 from "./Slider3";
import Slider5 from "./Slider5";
import "./css/main.css"
import { useFilters } from "./hooks/useFilters"
import { Link } from "react-router-dom";
import { PRODUCTS_PAGE, PROMOS } from "../../../config/router/routes";
import usePromotions from "./hooks/usePromotions";
import imagenes1 from "../assets/imagenes-01.svg"

export default function Main() {
    const { promotions } = usePromotions()
    const { mapedProducts } = useFilters()
    console.log(mapedProducts);

    return (
        <main className="main">

            <section className="home-carrusel">
                <Slider imagenes={mapedProducts}></Slider>
            </section>

            <section className="divisiones">
                <div className="section-header">
                    <p className="section-area">DIVISIONES</p>
                    <h3 className="section-title">Una línea para cada necesidad</h3>
                </div>
                <div className="divisiones-carrusel">
                    <Slider3 product={mapedProducts} ></Slider3>
                </div>
            </section>

            <section className="seleccion">
                <div className="section-header">
                    <p className="section-area">NUESTRA SELECCIÓN</p>
                    <h3 className="section-title">Productos destacados</h3>
                </div>
                <Slider5 product={mapedProducts}></Slider5>
                <Link to={`/${PRODUCTS_PAGE}`}><button className="ver-mas">VER MÁS</button></Link>
            </section>

            <section className="registro">
                {<Registrate imagenes={mapedProducts}></Registrate>}
            </section>

            <section className="promos">
                <div className="section-header">
                    <p className="section-area">PROMOS</p>
                    <h3 className="section-title">Descubrí</h3>
                </div>
                <Slider2 product={promotions}></Slider2>
                <Link to={`/${PROMOS}`}><button className="ver-mas">VER MÁS</button></Link>

            </section>

            <section className="esenciales">
                <div className="section-header">
                    <p className="section-area">CUIDADOS ESENCIALES</p>
                    <h3 className="section-title">Lociones & Brumas</h3>
                </div>
                <Slider5 product={mapedProducts}></Slider5>
                <Link to={`/${PRODUCTS_PAGE}`}><button className="ver-mas">VER MÁS</button></Link>
            </section>

        </main>
    )
}
