
import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import "../../css/dropFileInput.css";

const DropFileInput = ({ onFileUpload }) => {

  const [uploadFile, setUploadFile] = useState(null);

  const wrapperRef = useRef(null);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = e => {
    const newFile = e.target.files[0];
    if (newFile) {
      setUploadFile(newFile);
    }
  };

  useEffect(() => {
    if (uploadFile) {
      if (window.confirm("¿Desea cargar este archivo?")) {
        onFileUpload(uploadFile).then(async response => {
          if (!response.ok) throw new Error(await response.text());
          else alert("Se ha cargado el archivo correctamente.");
        }).catch((error) => {
          console.log(error);
          alert("No se pudo cargar el archivo. Descripcion: " + error.message );
        });
      }
      else {
        setUploadFile(null);
      }
    };
  }, [uploadFile, onFileUpload]);

  return (
    <>
      <Container
        ref={wrapperRef}
        className="drop-file-input d-flex flex-column justify-content-center align-items-center"
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <Row>
          <Col className="d-flex justify-content-center align-items-center text-center">
            <img src="/assets/images/cloudup_icon.png" alt="Icono Cloudup" width="200px" hight="200px"></img>
          </Col>
        </Row>
        <Row className="justify-content-center mt-3">
          <Col xs={8} className="text-center">
            <p>Arrastra tu archivo y sueltalo aquí o abre el <span id="green-text">explorador
              <input
                type="file"
                title=" "
                value=""
                onChange={onFileDrop}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </span>.
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-center">
          <Col className="text-center">
            <p>Formato admitido: csv.</p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DropFileInput;

