import React, { useRef } from "react";
import { Form } from "react-bootstrap";
import { Overlay, Tooltip } from "react-bootstrap";
const objectPath = require("object-path");

export const ControlSelect = ({
  name,
  label,
  labelNull = true,
  placeholder,
  onChange,
  options,
  data,
  start,
  end,
  errors = [],
  ...moreOptions
}) => {
  const target = useRef();
  const value = data ? objectPath(data).get(name, "") : "";
  const error = errors.find((e) => e.path === name);
  return (
    <>
      <div ref={target} 
      className={labelNull && "row mb-3 row w-100 d-flex flex-row"}
      >
        { labelNull &&
          <label htmlFor={name} className="col-sm-4 col-form-label">
          {label}
        </label>
        }
        <div
          className={labelNull && `col-sm-8 d-flex flex-row`}
          style={{ backgroundColor: "white" }}
        >
          {start}
          <Form.Select
            data-live-search="true"
            id={name}
            name={name}
            {...moreOptions}
            onChange={onChange}
            value={value}
            className="flex-grow-1 h-100"
            style={{ border: "0px solid white" }}
          >
            <option value="">{placeholder}</option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Select>
          {end}
        </div>
      </div>
      <Overlay target={target.current} show={error} placement="right">
        {(props) => (
          <Tooltip
            {...{ ...props, style: { ...(props.style || {}), opacity: 0.8 } }}
          >
            {error?.message}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};
