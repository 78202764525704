import { useState, useEffect } from 'react';
import { ControlText } from '../../../helpers';
import { ControlTextarea } from '../../../helpers/ControlTextarea';
import { ListControl } from './ListControl';

export const PrincipalActives = ({ entityErrors, entity, onControlChange }) => {
  const [principalActives, setPrincipalActives] = useState([
    { title: '', description: '' },
  ]);
  const addNewActive = () => {
    setPrincipalActives([...principalActives, { title: '', description: '' }]);
    
  };

  const removeLastActive = () => {
    const last = principalActives.length - 1;
    onControlChange({ target: { name: 'activeTitle-' + last, value: '' } });
    onControlChange({
      target: { name: 'activeDescription-' + last, value: '' },
    });
    setPrincipalActives(principalActives.slice(0, principalActives.length - 1));
  };

  useEffect(() => {
    onControlChange &&
      onControlChange({
        target: { name: 'principalActives', value: principalActives },
      });
  }, [principalActives]);

  const localChange = (e, index, type) => {
    const activesCopy = principalActives;
    principalActives[index][type] = e.target.value;
    setPrincipalActives(activesCopy);
    onControlChange(e);
  };
  

  const activePrinciplesContent = entity.purpose.activePrinciples
  useEffect(() => {
   
    if(activePrinciplesContent !== undefined){
      const newPrincipalActives = activePrinciplesContent.map((activePrinciple) => {
        return {
          title: activePrinciple.title,
          description: activePrinciple.description,
        };
      });
    
      setPrincipalActives(newPrincipalActives);
    } 
  }, [])

  return (
    <div>
      {principalActives.map(({ title, description }, index) => (
        <div key={'pricipal' + index}>
          <ControlText
            name={'activeTitle-' + index}
            label={index === 0 ? 'Principios activos' : ''}
            errors={entityErrors}
            data={entity} 
            onChange={(e) => localChange(e, index, 'title')}
            placeholder={'Titulo'}
            // disabled={entity.uso !== 'Público'}
          />
          <ControlTextarea
            name={'activeDescription-' + index}
            label=""
            errors={entityErrors}
            data={entity}
            onChange={(e) => localChange(e, index, 'description')}
            placeholder={'Descripción'}
            // disabled={entity.uso !== 'Público'}
          />
        </div>
      ))}
      <ListControl
        addNew={addNewActive}
        removeLast={removeLastActive}
        list={principalActives}
      />
    </div>
  );
};
