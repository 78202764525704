import { useEffect, useRef, useState } from "react";
import { MdSave } from "react-icons/md";
import { RiErrorWarningFill, RiShowersFill } from "react-icons/ri";
import { TiDelete, TiEdit, TiPlus } from "react-icons/ti";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { PopupDelete } from "../../components/abm/PopupDelete";
import { ProductForm } from "../../components/abm/stores/ProductsForm";
import { Table } from "../../components/abm/Table";
import { abm, updateStock, useAbm } from "../../services/abm";
import * as yup from "yup";
import { Abm } from "./_Abm";
import DropFileInput from "../../components/helpers/DropFileInput";
import { Row } from "react-bootstrap";
import "../../css/products.css";
import { AiOutlineSearch } from "react-icons/ai";

const schemaAdd = yup.object().shape({
  adminEmail: yup
    .string()
    .email("Email no es válido")
    .required("Email es un campo obligatorio"),
  password: yup.string().required("Contraseña es un campo obligatorio"),
  namespace: yup.string().required("Subdominio es un campo obligatorio"),
  name: yup.string().required("Nombre es un campo obligatorio"),
  division: yup.string().required("Division es un campo obligatorio"),
  address: yup.object().shape({
    street: yup.string(),
    externalNumber: yup.string(),
    zipCode: yup.string(),
    country: yup.string(),
    province: yup.string(),
    city: yup.string(),
    town: yup.string(),
  }),
  socialData: yup.object().shape({
    facebook: yup.string(),
    instagram: yup.string(),
    mobilePhoneNumber: yup.string(),
  }),
});

const schemaAddProd = yup.object().shape({
  division: yup.string().required("Division es un campo obligatorio"),
  categories: yup.array(),
  photos: yup.array(),
  purpose: yup.object().shape({
    id: yup.number(),
    primaryTitle: yup
      .string()
      .required("Titulo primario es un campo requerido"),
    // secondaryTitle: yup.string().required("Titulo secundario es un campo requerido"),
    description: yup.string().required("Descripcion es un campo requerido"),
    content: yup.object().shape({
      // id: yup.number(),
      //volume: yup.string(),
      //weight: yup.string(),
    }),
    details: yup.array(),
    waysToUse: yup.string(),
    code: yup.number(),
    // price: yup.number(),
    //publicPrice: yup.number(),
    //professionalPrice: yup.number(),
    activePrinciples: yup.array(),
  }),
  variants: yup.array(),
});

let schemaEdit = yup.object().shape({
  // sector: yup.object().shape({ id: yup.string() }), //.required("Sector es un campo obligatorio") }),
  categories: yup.array(),
  photos: yup.array(),
  purpose: yup.object().shape({
    id: yup.number(),
    primaryTitle: yup.string(), //.required("Titulo primario es un campo requerido"),
    // secondaryTitle: yup.string(), //.required("Titulo secundario es un campo requerido"),
    description: yup.string(), //.required("Descripcion es un campo requerido"),
    content: yup.object().shape({
      // id: yup.number(),
      //volume: yup.number(),
      //weight: yup.string(),
    }),
    details: yup.array(),
    waysToUse: yup.string(),
    code: yup.number(),
    //price: yup.number(),

    activePrinciples: yup.array(),
  }),
  variants: yup.array(),
});

const Products = () => {
  const {
    getAll,
    create,
    update,
    remove,
    getOne,
    uploadMassiveFile,
    getStockByNamespace,
  } = useAbm({
    entity: "products",
  });
  const [rows, setRows] = useState([]);
  const [editItem, setEditItem] = useState(null);
  const [removeItem, setRemoveItem] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [error, setError] = useState(null);
  const [activeKey, setActiveKey] = useState("administrar");
  const [sectorId, setSectorId] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const firstRender = useRef(true); // Para evitar la ejecución en el primer render

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false; // Marcar como ya ejecutado
      return; // Salir del useEffect sin hacer nada
    }

    if (activeKey === "administrar") {
      const timeout = setTimeout(() => {
        firstRender.current = true;
        window.location.reload(); // Recargar la página
      }, 2000);

      return () => clearTimeout(timeout); // Limpiar timeout si cambia antes de los 3s
    }
  }, [activeKey]);

  const administrarBreadcrumb = [
    { caption: "Productos", href: "#" },
    { caption: "Administrar", href: "#" },
  ];

  const aniadirBreadcrumb = [
    { caption: "Productos", href: "#" },
    { caption: "Añadir", href: "#" },
  ];

  const cargaBreadcrumb = [
    { caption: "Productos", href: "#" },
    { caption: "Carga masiva", href: "#" },
  ];
  const [breadcrumb, setBreadcrumb] = useState(administrarBreadcrumb);

  const [stocks, setStocks] = useState([]);
  const initialStocks = useRef([]);

  const columnsCentral = [
    {
      field: "visible",
      caption: "VISIBLE",
      render: (row) => (
        <Row className="justify-content-center">
          <FaEye size={22} color="#5cb8b2" />
        </Row>
      ),
    },
    { field: "code ", caption: "COD", render: (row) => row.purpose?.code },
    {
      field: "primaryTitle",
      caption: "TÍTULO PRIMARIO",
      render: (row) => row.purpose?.primaryTitle,
    },
    {
      field: "secondaryTitle",
      caption: "TÍTULO SECUNDARIO",
      render: (row) => row.purpose?.secondaryTitle,
    },
    {
      field: "content",
      caption: "CONTENIDO",
      render: (row) => row.purpose?.content?.weight,
    },
    {
      field: "use",
      caption: "USO",
      render: (row) => (row.purpose?.price ? "Profesional" : "Público"),
    },
    {
      field: "category1",
      caption: "CAT 1",
      render: (row) =>
        row.categorie && row.categorie.length > 0
          ? row.categorie[0].name
          : "No hay categoría",
    },
    {
      field: "category2",
      caption: "CAT 2",
      render: (row) =>
        row.categorie && row.categorie.length > 1
          ? row.categorie[1].name
          : "No hay categoría",
    },
    {
      field: "category3",
      caption: "CAT 3",
      render: (row) =>
        row.categorie && row.categorie.length > 2
          ? row.categorie[2].name
          : "No hay categoría",
    },
    {
      field: "professionalPrice",
      caption: "PRECIO PROF.",
      render: (row) =>
        row.purpose?.professionalPrice
          ? "$" + row.purpose?.professionalPrice
          : "-",
    },
    {
      field: "publicPrice",
      caption: "PRECIO PÚB.",
      render: (row) =>
        row.purpose?.publicPrice
          ? "$" + row.purpose?.publicPrice
          : row.purpose?.price
          ? "$" + row.purpose.price
          : "-",
    },
    {
      field: "division",
      caption: "DIVISIÓN",
      render: (row) => row.division.name,
    },
  ];

  const columns = [
    {
      field: "product",
      caption: "PRODUCTO",
      render: (row) =>
        row.img ? (
          <img
            src={URL.createObjectURL(row.img)}
            className="p-0"
            alt="Imagen principal"
            width="300px"
            style={{ objectFit: "contain" }}
          />
        ) : null,
    },
    {
      field: "title",
      caption: "TÍTULO",
      render: (row) => (
        <div>
          <b>{row.purpose.primaryTitle}</b>
          <p className="m-0">{row.purpose.secondaryTitle}</p>
        </div>
      ),
    },
    {
      field: "professionalPrice",
      caption: "PRECIO PROF.",
      render: (row) =>
        row.purpose.professionalPrice
          ? "$" + row.purpose.professionalPrice
          : "-",
    },
    {
      field: "publicPrice",
      caption: "PRECIO PÚB.",
      render: (row) =>
        row.purpose.publicPrice
          ? "$" + row.purpose.publicPrice
          : row.purpose.price
          ? "$" + row.purpose.price
          : "-",
    },
    {
      field: "content",
      caption: "CONTENIDO",
      render: (row) => row.purpose.content.weight,
    },
    {
      field: "use",
      caption: "USO",
      render: (row) => (row.purpose.price ? "Profesional" : "Público"),
    },
    {
      field: "division",
      caption: "DIVISIÓN",
      render: (row) => row.division.name,
    },
    {
      field: "variants",
      caption: "VARIANTES",
      render: (row) =>
        !row.variants || row.variants.length === 0 ? "No" : "Si",
    },
    {
      field: "stock",
      caption: "STOCK",
      render: (row) => (
        <div
          style={{
            padding: "10px",
            width: "50px",
            border: "2px solid #cdc9c9",
            borderRadius: "3px",
          }}
        >
          <input
            id={`stockInput-${row.id}`}
            className="stockInput"
            type="number"
            value={
              stocks.find((productStock) => productStock?.productId === row.id)
                ?.stock
            }
            onChange={(e) => {
              const value = parseInt(e.target.value);
              const newStocks = [...stocks];
              const productStock = newStocks.find(
                (productStock) => productStock?.productId === row.id
              );
              productStock.stock = value;
              setStocks(newStocks);
            }}
            onBlur={(e) => {
              const value = parseInt(e.target.value);
              updateProductStock(value, row.id);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                const value = parseInt(e.target.value);
                updateProductStock(value, row.id);
                e.currentTarget.blur();
              }
            }}
            style={{ border: "none", width: "100%" }}
          />
        </div>
      ),
    },
  ];

  const updateProductStock = (value, id) => {
    if (
      value !==
      initialStocks.current.find(
        (productStock) => productStock?.productId === id
      )?.stock
    ) {
      const namespace = localStorage.getItem("namespace");
      updateStock(id, namespace, value);
      initialStocks.current = stocks.map((object) => ({ ...object }));
    }
  };

  const actions = ({ row }) => [
    {
      name: "editar",
      caption: "Editar",
      icon: <TiEdit size={22} color="orange" />,
      onClick: (row) => {
        console.log(row);
        handleEditItem(row);
      },
    },
    {
      name: "eliminar",
      caption: "Eliminar",
      icon: <TiDelete size={22} color="red" />,
      onClick: (row) => {
        handleRemoveItem(row);
      },
    },
  ];

  const handleRemoveItem = (row) => {
    setRemoveItem(row);
    setShowConfirmDelete(true);
  };

  const handleRemoveItemConfirm = ({ user, password }) => {
    setShowConfirmDelete(false);
    remove(removeItem.id);
    setRemoveItem(null);
  };

  const base64toBlob = (imgBase64) => {
    const byteCharacters = atob(imgBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "multipart/form-data" });
    return blob;
  };

  const newFile = (img, name, type) => {
    const file = new File([img], name, {
      type: type,
    });
    return file;
  };

  const fetchData = () => {
    getAll()
      .then((data) => {
        setRows(
          data.products.map((item) => {
            if (item.photos[0]?.base64 !== undefined) {
              const imgProduct = base64toBlob(item.photos[0].base64);
              const fileProduct = newFile(
                imgProduct,
                `producto-${item.id}.png`,
                imgProduct.type
              );
              return { ...item, id: item.id, img: fileProduct };
            }
            return { ...item, id: item.id };
          })
        );
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  const handleEditItem = (row) => {
    row.details = row.purpose.details.join(",");
    if (row.purpose?.activePrinciples?.size > 0) {
      row.purpose?.activePrinciples?.map((principle, index) => {
        row["activeTitle-" + index] = principle.title;
        row["activeDescription-" + index] = principle.description;
      });
    } else {
      row.purpose.activePrinciples = [{ title: "", description: "" }];
    }

    if (row.variants?.size > 0) {
      row.variants?.map((variant, index) => {
        row["variantsTitle-" + index] = variant.title;
        row["variantsColour-" + index] = variant.colour;
        row["variantsCode-" + index] = variant.code;
        row["variantsPublicPrice-" + index] = variant.publicPrice;
        row["variantsProfessionalPrice-" + index] = variant.professionalPrice;
      });
    } else {
      row.variants = [{}];
    }

    row.uso = row.purpose.price ? "Profesional" : "Público";
    row.purpose.publicPrice = row.purpose.publicPrice
      ? row.purpose.publicPrice
      : row.purpose.price;
    row.content = row.purpose.content.weight;
    row.volume = row.purpose.content.volume;
    //  row.categoryPrimary = row.categorie.parentCategory.name;
    row.categorySecondary = row.categorie.name ? row.categorie.name : [];
    setEditItem(schemaEdit.cast({ ...row, division: row.division.name }));
  };

  const handleFormCancel = () => {
    setEditItem(null);
    handleSelectTab("administrar");
  };
  const handleAddConfirm = (data, images) => {
    console.log(data);
    console.log(images);
    create(data)
      .then(async (result) => {
        await uploadImages(result.id, images);
        //window.location.reload();
        firstRender.current = false;
        handleSelectTab("agregar");
        handleSelectTab("administrar");
      })
      .catch((error) => {
        setError(error.headers.get("message") || "Error agregando tienda");
      });
  };

  const uploadImages = async (id, images) => {
    console.log("images", images);
    if (images.principal) {
      const files = Array.from(images.principal);
      files.forEach(async (image) => {
        const res = await abm("images").uploadImageProduct(
          {
            image,
            description: "principal",
            type: 1,
          },
          id
        );
      });
    }

    if (images.galeria) {
      const files = Array.from(images.galeria);

      files.forEach(async (image) => {
        const res = await abm("images").uploadImageProduct(
          {
            image,
            description: "galeria",
            type: 1,
          },
          id
        );
        console.log("galeria", res);
      });
    }

    if (images.portada) {
      const files = Array.from(images.portada);

      files.forEach(async (image) => {
        const res = await abm("images").uploadImageProduct(
          {
            image,
            description: "portada",
            type: 1,
          },
          id
        );
      });
    }
  };

  const handleEditConfirm = (data, images) => {
    update(data, data.id)
      .then(async (result) => {
        await uploadImages(result.id, images);
        //window.location.reload();
        firstRender.current = false;
        handleSelectTab("editar");
        handleSelectTab("administrar");
      })
      .catch((error) => {
        setError(error.headers.get("message") || "Error guardando cambios");
      });
  };

  const handleUploadMassiveFile = (file) => {
    return uploadMassiveFile(file);
  };
  
  const filteredRows = rows.filter((row) =>
    row.purpose?.primaryTitle.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const tabs = [
    {
      key: "administrar",
      title: "Administrar",
      content: () =>
        !editItem ? (
          <div className="mt-4 gg" style={{ position: "relative" }}>
            <input
              type="text"
              placeholder="Buscar productos..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search"
              style={{ margin: "10px", padding: "5px", width: "450px", borderRadius: "20px", 
               outline: "none", border: "none", position: "absolute",top: "-80px", right: "0" }}
            />
            {/* <div className="search-container" style={{ backgroundColor: "red" }}>
                      <input placeholder="Buscar por título o código" className="search" style={{ position: "absolute", top: "0", left: "0", right:"500px", width: "100%", height: "100%", padding: "5px", borderRadius: "20px", border: "none", outline: "none" }}
                      value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                      <AiOutlineSearch className="search-icon" size={22} />
                    </div> */}
            <Table
              columns={sectorId !== 0 ? columns : columnsCentral}
              rows={filteredRows}
              actions={sectorId !== 0 ? null : actions}
              sectorId={sectorId}
            />
          </div>
        ) : (
          <ProductForm
            item={editItem}
            onCancel={handleFormCancel}
            schema={schemaEdit}
            onConfirm={handleEditConfirm}
            confirmLabel={
              <>
                <MdSave size={18} color="white" /> Guardar
              </>
            }
            cancelLabel={
              <>
                <TiDelete size={22} color="white" /> Cancelar
              </>
            }
            title={`Editando el Producto: ${editItem.purpose.primaryTitle}`}
            errorState={[error, setError]}
          />
        ),
      onSelect: () => {
        //mock api result
        setEditItem(null);
        fetchData();
      },
    },
    {
      key: "añadir",
      title: "Añadir",
      content: () => (
        <ProductForm
          item={schemaAddProd.getDefault()}
          schema={schemaAddProd}
          onCancel={handleFormCancel}
          onConfirm={handleAddConfirm}
          confirmLabel={
            <>
              <TiPlus size={22} color="white" />
              Añadir
            </>
          }
          errorState={[error, setError]}
          cancelLabel={
            <>
              <TiDelete size={22} color="white" /> Cancelar
            </>
          }
        />
      ),
      onSelect: () => {},
    },
    {
      key: "carga",
      title: "Carga masiva",
      content: () => <DropFileInput onFileUpload={handleUploadMassiveFile} />,
      onSelect: () => {},
    },
  ];

  const handleCloseConfirmDelete = () => {
    console.log("cerrando dialogo confirmar ");
    setRemoveItem(null);
    setShowConfirmDelete(false);
  };

  const handleSelectTab = (key) => {
    tabs.find((tab) => tab.key === key)?.onSelect();
    setActiveKey(key);
  };

  useEffect(() => {
    const namespace = localStorage.getItem("namespace");
    setSectorId(+localStorage.getItem("sectorId"));
    getStockByNamespace(namespace).then((res) => {
      setStocks(res.stocks);
      initialStocks.current = res.stocks.map((object) => ({ ...object }));
    });

    if (activeKey === "carga") setBreadcrumb(cargaBreadcrumb);
    else if (activeKey === "añadir") setBreadcrumb(aniadirBreadcrumb);
    else setBreadcrumb(administrarBreadcrumb);
  }, [activeKey, sectorId]);

  return (
    <>
      {/* <div>
      <input
       type="text"
       placeholder="Buscar productos..."
       value={searchTerm}
       onChange={(e) => setSearchTerm(e.target.value)} style={{ marginBottom: "10px", padding: "5px", width: "100%" }}
      />
      <Table rows={filteredRows} columns={columns} actions={actions} />
      </div> */}
      <Abm
        PopupDelete={
          <PopupDelete
            message={
              <>
                Estás a punto de eliminar el producto <b>{removeItem?.name}</b>
                <br />
                Esta acción no se puede deshacer.
              </>
            }
            onClose={handleCloseConfirmDelete}
            show={showConfirmDelete}
            showLogin={false}
            onConfirm={handleRemoveItemConfirm}
            onCancel={handleCloseConfirmDelete}
            title={
              <>
                <RiErrorWarningFill color="var(--bs-danger)" size={26} />
                <span className="px-3">¡Atención!</span>
              </>
            }
          />
        }
        keyState={[activeKey, setActiveKey]}
        tabs={tabs}
        breadcrumb={breadcrumb}
        title="Productos"
      />
    </>
  );
};

export default Products;
